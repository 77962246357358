import { BranchesOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Modal, Popconfirm, Switch } from "antd";
import React, { useState } from "react";
import CustomQuill from "../../../../components/CustomQuill";
import { useGlobalState } from "../../../../components/global";
import GlobalWrap from "../../../../components/globalWrap";
import ContentImageUploader from "../../../components/ContentImageUploader";
import ContentSourceForm from "../ContentSourceForm";
import { useWatch } from "antd/lib/form/Form";

const { TextArea } = Input;

export default function RichTextInput({
    field,
    prefix,
    name,
    label,
    form,
    fullPrefix,
    changePrefix = fullPrefix,
    sourceName,
}: any) {
    const { extData, isRequired } = field || {};
    const [showCode, setShowCode]: any = useState(false);
    const [sourceForm] = Form.useForm();
    const sourceWatch = useWatch([...fullPrefix, sourceName], form);
    let sourceModal: any;
    
    const { jsonRequest, currentTenant } = useGlobalState();

    const imgUploadApi = async (fd: any) => {
        return await ContentImageUploader(jsonRequest, currentTenant, fd.get("file"));
    };

    const onChanged = () => {
        form.setFieldValue([...changePrefix.slice(0, -1), "change"], true);
    };

    const setStylizeText = (html: any) => {
        if (name) return;
        form.setFieldValue([...fullPrefix, "stylizeDescription"], html);
    };
    
    const deleteSource = () => {
        sourceModal?.destroy();
        form.setFieldValue([...fullPrefix, sourceName], undefined);
        onChanged();
    }

    const handleSourceForm = () => {
        const source = form.getFieldValue([...fullPrefix, sourceName]);
        sourceModal = Modal.confirm({
            width: 800,
            title: "内容来源",
            content: (
                <GlobalWrap>
                    <ContentSourceForm form={sourceForm} init={source} />
                </GlobalWrap>
            ),
            onOk: (close) => {
                sourceForm
                    .validateFields()
                    .then((values) => {
                        const {systemCode, type, result}  = values;
                        form.setFieldValue([...fullPrefix, sourceName], { systemCode, type, ...result });
                        onChanged();
                        close();
                        return false;
                    })
                    .catch((e) => console.log(e));
            },
            afterClose: () => sourceForm.resetFields(),
            footer: (params: any) => <div>
                <Popconfirm title="确认删除来源吗？" okText="是" cancelText="否"
                            onConfirm={deleteSource}>
                    <Button danger disabled={!source}>删除来源</Button>
                </Popconfirm>
                {params}
            </div>
        });
    };

    return (
        <>
            <Form.Item name={[...prefix.slice(0, -1), "change"]} initialValue={false} hidden>
                <Input />
            </Form.Item>
            <Form.Item label="查看源码">
                <Flex justify="space-between" align="center">
                    <Switch defaultChecked={showCode} onChange={(v) => setShowCode(v)} />
                    {sourceName && (
                        <Button
                            type="link"
                            icon={<BranchesOutlined />}
                            danger={!sourceWatch}
                            onClick={handleSourceForm}
                        >
                            {sourceWatch ? sourceWatch.fullName || sourceWatch.contentName || sourceWatch.cateName : '内容来源'}
                        </Button>
                    )}
                </Flex>
            </Form.Item>
            <Form.Item name={[...prefix, "stylizeDescription"]} initialValue={""} hidden>
                <Input />
            </Form.Item>
            <Form.Item
                label={label}
                name={[...prefix, name || "description"]}
                wrapperCol={{ span: label ? 21 : 23 }}
                initialValue={""}
                rules={[{ required: isRequired, message: "不能为空" }]}
            >
                {showCode ? (
                    <TextArea rows={3} />
                ) : (
                    <CustomQuill
                        imgUploadApi={imgUploadApi}
                        onChange={onChanged}
                        setStylizeText={setStylizeText}
                        placeholder={extData?.inputPrompt}
                    />
                )}
            </Form.Item>
        </>
    );
}
