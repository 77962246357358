import { BranchesOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Modal, Popconfirm } from "antd";
import { useWatch } from "antd/lib/form/Form";
import GlobalWrap from "../../../../components/globalWrap";
import ContentSourceForm from "../ContentSourceForm";

const { TextArea } = Input;

export default function StringInput({
    prefix,
    name,
    field,
    form,
    fullPrefix,
    label,
    changePrefix = fullPrefix,
    sourceName,
}: any) {
    const { extData } = field;
    const { inputPrompt } = extData ?? {};
    const [sourceForm] = Form.useForm();
    const sourceWatch = useWatch([...fullPrefix, sourceName], form);
    let sourceModal: any;

    const deleteSource = () => {
        sourceModal?.destroy();
        form.setFieldValue([...fullPrefix, sourceName], undefined);
        onChanged();
    };
    
    const onChanged = () => {
        form.setFieldValue([...changePrefix.slice(0, -1), "change"], true)
    }

    const handleSourceForm = () => {
        const source = form.getFieldValue([...fullPrefix, sourceName]);
        sourceModal = Modal.confirm({
            width: 800,
            title: "内容来源",
            content: (
                <GlobalWrap>
                    <ContentSourceForm form={sourceForm} init={source} />
                </GlobalWrap>
            ),
            onOk: (close) => {
                sourceForm
                    .validateFields()
                    .then((values) => {
                        const {systemCode, type, result}  = values;
                        form.setFieldValue([...fullPrefix, sourceName], { systemCode, type, ...result });
                        onChanged();
                        close();
                        return false;
                    })
                    .catch((e) => console.log(e));
            },
            afterClose: () => sourceForm.resetFields(),
            footer: (params: any) => (
                <div>
                    <Popconfirm
                        title="确认删除来源吗？"
                        okText="是"
                        cancelText="否"
                        onConfirm={deleteSource}
                    >
                        <Button danger disabled={!source}>
                            删除来源
                        </Button>
                    </Popconfirm>
                    {params}
                </div>
            ),
        });
    };

    return (
        <>
            {sourceName && (
                <Form.Item wrapperCol={{ span: 23 }}>
                    <Flex justify="end">
                        <Button
                            type="link"
                            icon={<BranchesOutlined />}
                            danger={!sourceWatch}
                            onClick={handleSourceForm}
                        >
                            {sourceWatch
                                ? sourceWatch.fullName || sourceWatch.contentName || sourceWatch.cateName
                                : "内容来源"}
                        </Button>
                    </Flex>
                </Form.Item>
            )}
            <Form.Item
                name={[...prefix, name || "description"]}
                label={label}
                wrapperCol={{ span: label ? 21 : 23 }}
                initialValue={""}
            >
                <TextArea
                    placeholder={inputPrompt}
                    autoSize={{ maxRows: 5 }}
                    onChange={onChanged}
                />
            </Form.Item>
        </>
    );
}
