import { Alert } from "antd";
import RichTextInput from "./medias/RichTextInput";
import StringInput from "./medias/StringInput";

export default function CutsomKeyMedia({ field, form, prefix, fullPrefix, changePrefix }: any) {
    const { mediaType, extData } = field || {};
    const temp = extData?.params ? JSON.parse(extData.params) : [{ title: "", key: "description" }];

    const MediaBody = ({ name, label }: any) => {
        switch (mediaType) {
            case "string_array":
                return (
                    <StringInput
                        label={label}
                        prefix={prefix}
                        name={name}
                        field={field}
                        form={form}
                        fullPrefix={fullPrefix}
                        changePrefix={changePrefix}
                    />
                );
            case "string_array_extend":
                return (
                    <StringInput
                        label={label}
                        prefix={[...prefix, name]}
                        name="description"
                        field={field}
                        form={form}
                        fullPrefix={[...fullPrefix, name]}
                        changePrefix={changePrefix}
                        sourceName="source"
                    />
                );
            case "rich_text_array":
                return (
                    <RichTextInput
                        label={label}
                        prefix={prefix}
                        name={name}
                        field={field}
                        form={form}
                        fullPrefix={fullPrefix}
                        changePrefix={changePrefix}
                    />
                );
            case "rich_text_array_extend":
                return (
                    <RichTextInput
                        label={label}
                        prefix={[...prefix, name]}
                        name="description"
                        field={field}
                        form={form}
                        fullPrefix={[...fullPrefix, name]}
                        changePrefix={changePrefix}
                        sourceName="source"
                    />
                );
        }
        return <Alert message="为受支持的媒体类型" type="error" />;
    };

    if (!Array.isArray(temp)) {
        console.error("模板信息有误，Json内容应是一个数组。", temp);
        return <Alert message="模板信息有误，请联系管理员。" type="error" />;
    }
    return (
        <>
            {temp.map(({ key, title }: any) => (
                <MediaBody name={key} label={title} />
            ))}
        </>
    );
}
