import ContentFieldItem from "./ContentFieldItem";
import { Button, Form, Input, InputNumber, Modal, Popconfirm, Popover, Space, Tooltip } from "antd";
import MultiInputGroup from "./MultiInputGroup";
import {
    ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined,
    FormOutlined, LinkOutlined, MergeOutlined,
    NodeIndexOutlined, PlusOutlined, QuestionCircleFilled,
} from "@ant-design/icons";
import GlobalWrap from "../../../components/globalWrap";
import InsertForm from "./InsertForm";
import ImportsForm from "./ImportsForm";
import "./MediaList.css";
import LinkForm from "./LinkForm";
import MediaCollapse from "./MediaCollapse";
import React from "react";
import { getMediaAnchorKey } from "./ContentAnchor/ContentAnchor";
import FieldDescription from "./FieldDescription";

export default function MediaList({ form, type, systemCode, fieldType }: any) {
    const [insertForm] = Form.useForm();
    const [linkForm] = Form.useForm();
    let linkModal: any;

    const handleMediaInsert = (e: any, media: any, prefix: any, onOk: any) => {
        e.stopPropagation();
        Modal.confirm({
            width: 500,
            title: `${media.mediaName ? "编辑" : "插入"}媒体`,
            content: <GlobalWrap><InsertForm form={insertForm} media={media} /></GlobalWrap>,
            onOk: (close) => {
                insertForm.validateFields()
                  .then((values) => {
                      if (media.mediaName && ["group", "title"].includes(values.mediaType)) {
                          values.change = true;
                      }
                      form.setFieldValue(prefix, { ...media, ...values });
                      onOk && onOk();
                      close();
                      return false;
                  })
                  .catch((e) => {
                      console.log(e);
                  });
            },
            onCancel: (close) => {
                close();
            },
        });
    }; 

    const onImportMedia = (media: any, prefix: any) => {
        const { mediaId, mediaType, extData } = media.media;
        const origin = form.getFieldValue(prefix);
        const newMediaId = media.type === "ref" ? mediaId : undefined;
        form.setFieldValue(prefix, {
            ...origin, mediaType,
            mediaId: newMediaId,
            source: media.type,
            extData: extData,
        });
    };

    const handleMediaImport = (e: any, prefix: any, mediaType: any) => {
        const { systemCode } = form.getFieldsValue();
        e.stopPropagation();
        const modal = Modal.confirm({
            width: 1200,
            title: "媒体导入",
            okButtonProps: { style: { display: "none" } },
            content: <GlobalWrap>
                <ImportsForm systemCode={systemCode} mediaType={mediaType}
                             onImport={(media: any) => {
                                 onImportMedia(media, prefix);
                                 modal.destroy();
                             }} />
            </GlobalWrap>,
            afterClose: () => form.validateFields(prefix, { validateOnly: true }), // 触发FormItem刷新，否则mediaName不会改变
        });
    };

    const deleteLink = (prefix: any) => {
        linkModal?.destroy();
        const extPrefix = [...prefix, "extData"];
        const { link, ...others } = form.getFieldValue(extPrefix);
        form.setFieldValue(extPrefix, others);
        form.validateFields(prefix, { validateOnly: true });
    };

    const handleMediaLink = (e: any, prefix: any) => {
        e.stopPropagation();
        const linkPrefix = [...prefix, "extData", "link"];
        const {
            sourceId,
            sourceName,
            chapterId,
            chapterName,
            systemCode,
            ...others
        } = form.getFieldValue(linkPrefix) || {};
        const link = {
            source: { label: sourceName, value: sourceId },
            chapter: { label: chapterName, value: chapterId },
            systemCode: systemCode || form.getFieldValue("systemCode"),
            ...others,
        };

        linkModal = Modal.confirm({
            width: 800,
            title: "内容关联",
            content: <GlobalWrap><LinkForm form={linkForm} link={link} /></GlobalWrap>,
            onOk: async (close) => {
                let { source, chapter, ...others } = await linkForm.validateFields();
                form.setFieldValue(linkPrefix, {
                    sourceId: source?.value,
                    sourceName: source?.label,
                    chapterId: chapter?.value,
                    chapterName: chapter?.label,
                    ...others,
                });
                form.validateFields(linkPrefix, { validateOnly: true });
                close();
                return false;
            },
            onCancel: (close) => {
                close();
            },
            footer: (params: any) => <div>
                <Popconfirm title="确认取消关联吗？" okText="是" cancelText="否"
                            onConfirm={() => deleteLink(prefix)}>
                    <Button danger disabled={!sourceId}>取消关联</Button>
                </Popconfirm>
                {params}
            </div>,
        });
    };

    const cancelRef = (e: any, prefix: any) => {
        e.stopPropagation();
        const { mediaId, source, ...others } = form.getFieldValue(prefix);
        form.setFieldValue(prefix, { ...others, source: "new", mediaId: undefined });
        form.validateFields(prefix, { validateOnly: true });
    };

    const CustomMediaForm = ({ index, prefix, listName }: any) => <>
        <Form.Item label="媒体类型" name={[index, "mediaType"]} hidden>
            <Input />
        </Form.Item>
        <Form.Item name={[index, "mediaId"]} hidden><Input /></Form.Item>
        <Form.Item label="字段标题" name={[index, "mediaName"]} hidden>
            <Input />
        </Form.Item>
        <Form.Item label="排序号" name={[index, "index"]} hidden>
            <InputNumber />
        </Form.Item>
        <Form.Item noStyle dependencies={[[...prefix, index, "mediaType"], [...prefix, index, "mediaName"]]}>
            {({ getFieldValue }) => {
                return <ContentFieldItem
                  field={{ ...getFieldValue([...prefix, index]) }} form={form}
                  prefix={[index, "extData"]} fullPrefix={[...prefix, index, "extData"]} listName={[listName]} />;
            }}
        </Form.Item>
    </>;

    const sortFieldList = (prefix: any) => {
        const list = form.getFieldValue(prefix);
        list.sort((a: any, b: any) => a.index - b.index);
        form.setFieldValue(prefix, list);
        form.validateFields(prefix, { validateOnly: true });
    };

    const exchangeIndex = (prefix: any, index1: any, index2: any) => {
        const fieldIndex1 = form.getFieldValue([...prefix, index1, "index"]);
        const fieldIndex2 = form.getFieldValue([...prefix, index2, "index"]);
        form.setFieldValue([...prefix, index1, "index"], fieldIndex2);
        form.setFieldValue([...prefix, index2, "index"], fieldIndex1);
        sortFieldList(prefix);
    };

    const OperationBar = ({ item, prefix, remove, listIndex, length, parentName }: any) => {
        const { sons, mediaId, mediaName, fieldKey, source, mediaType, index, extData } = item;
        const { link } = extData || {};
        const editPrefix = [...prefix, listIndex];
        const insertPrefix = [...prefix, listIndex, "sons", sons?.length || 0];
        return <Space>
            <Tooltip title="index"><Button shape="circle" size="small">{index}</Button></Tooltip>
            {source === "ref" && !!mediaId &&
              <Popconfirm title="确定取消引用吗？" onConfirm={(e: any) => cancelRef(e, editPrefix)}>
                <Tooltip title={<div style={{ textAlign: "center" }}>
                    <div>这是一个媒体的引用</div>
                    <div>修改此内容也会导致引用内容被修改</div>
                </div>}>
                  <Button shape="circle" size="small" icon={<LinkOutlined style={{ color: "#1677FF" }} />}
                          onClick={(e: any) => e.stopPropagation()} />
                </Tooltip>
              </Popconfirm>}
            <Tooltip title="导入">
                <Button icon={<NodeIndexOutlined />} shape="circle" type="primary" size="small"
                        onClick={(e: any) => handleMediaImport(e, editPrefix, mediaType)} />
            </Tooltip>
            <Tooltip title="插入">
                <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small"
                        onClick={(e: any) => handleMediaInsert(e, {
                              index: sons?.length + 1 || 1,
                              parentName: mediaName,
                          }, insertPrefix, () => sortFieldList(prefix))} />
            </Tooltip>
            <Tooltip title="内容关联">
                <Button icon={<MergeOutlined />} shape={link ? "round" : "circle"} type="primary" size="small"
                        onClick={(e: any) => handleMediaLink(e, editPrefix)}>
                    {link && link.title}
                </Button>
            </Tooltip>
            <Tooltip title="编辑">
                <Button icon={<FormOutlined />} shape="circle" type="primary" size="small"
                        onClick={(e: any) => handleMediaInsert(e, {
                            ...item,
                            parentName: parentName,
                        }, editPrefix, () => sortFieldList(prefix))} />
            </Tooltip>
            <Tooltip title="上移">
                <Button icon={<ArrowUpOutlined />} shape="circle" type="primary" size="small" disabled={listIndex === 0}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            exchangeIndex(prefix, listIndex, listIndex - 1);
                        }} />
            </Tooltip>
            <Tooltip title="下移">
                <Button icon={<ArrowDownOutlined />} shape="circle" type="primary" size="small"
                        disabled={listIndex === length - 1}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            exchangeIndex(prefix, listIndex, listIndex + 1);
                        }} />
            </Tooltip>
            <Tooltip title="删除">
                <Popconfirm title="确认删除吗？" okText="是" cancelText="否" onConfirm={() => remove(listIndex)}>
                    <Button shape="circle" disabled={fieldKey} danger icon={<DeleteOutlined />} size="small"
                            onClick={(e: any) => e.stopPropagation()} />
                </Popconfirm>
            </Tooltip>
        </Space>;
    };

    const CollapseMediaLabel = ({ prefix }: any) => {
        const label = form.getFieldValue(prefix);
        const parser = new DOMParser();
        const doc = parser.parseFromString(label, "text/html");
        return <div className="fixed-length-label">{doc.body.textContent || ""}</div>;
    };

    const MediasFormList = ({ prefix, listName, addable, parentName }: any) => {
        const listLength = form.getFieldValue(prefix)?.length || 0;
        return <MultiInputGroup name={listName} item={(listIndex: any, field: any, add: any, remove: any) => {
            const item = form.getFieldValue([...prefix, listIndex]);
            const {
                index, fieldName, fieldKey, fieldId, mediaId, mediaType, mediaName,
                source, hideTitle, viewType, isRequired, foldStatus,
            } = item || {};
            const boldTitle = mediaType === "title" || mediaType === "group";
            const isText = mediaType === "string" || mediaType === "rich_text";
            const elementId = getMediaAnchorKey({mediaId, mediaName});
            return <div id={elementId}>
                <MediaCollapse index={listIndex} defaultActiveKey={[field.key]} defaultOpen={type === "add"}
                               elementId={elementId} items={(active: any) => [{
                    key: field.key,
                    label: <Space>
                        {fieldId && <Popover 
                          content={<FieldDescription systemCode={systemCode} fieldId={fieldId} fieldType={fieldType} />} 
                          title="字段描述" 
                          trigger="hover">
                            <QuestionCircleFilled />
                        </Popover>}
                        <div style={{ textDecoration: hideTitle ? "line-through" : "none" }}
                             className={`${isRequired ? "required-media" : ""} ${boldTitle ? "group-media-name" : ""} fixed-length-label`}>
                            {fieldName || mediaName}
                        </div>
                        {isText && !active && foldStatus &&
                          <CollapseMediaLabel prefix={[...prefix, listIndex, "extData", "description"]} />}
                    </Space>,
                    extra: <OperationBar item={item} prefix={prefix} remove={remove} listIndex={listIndex}
                                         length={listLength} parentName={parentName} />,
                    children: <>
                        <Form.Item name={[field.name, "index"]} hidden initialValue={index}><Input /></Form.Item>
                        <Form.Item name={[field.name, "change"]} initialValue={false} hidden><Input /></Form.Item>
                        <Form.Item name={[field.name, "mediaName"]} hidden
                                   initialValue={fieldName}><Input /></Form.Item>
                        <Form.Item name={[field.name, "fieldKey"]} hidden
                                   initialValue={fieldKey}><Input /></Form.Item>
                        <Form.Item name={[field.name, "fieldId"]} hidden
                                   initialValue={fieldId}><Input /></Form.Item>
                        <Form.Item name={[field.name, "source"]} hidden
                                   initialValue={source || "new"}><Input /></Form.Item>
                        <Form.Item name={[field.name, "hideTitle"]} hidden
                                   initialValue={hideTitle || false}><Input /></Form.Item>
                        <Form.Item name={[field.name, "foldStatus"]} hidden
                                   initialValue={hideTitle || false}><Input /></Form.Item>
                        <Form.Item name={[field.name, "viewType"]} hidden
                                   initialValue={viewType || "default"}><Input /></Form.Item>
                        <Form.Item name={[field.name, "mediaId"]} hidden
                                   initialValue={mediaId}><Input /></Form.Item>
                        <Form.Item name={[field.name, "mediaType"]} hidden
                                   initialValue={mediaType}><Input /></Form.Item>
                        {fieldId ?
                          <ContentFieldItem field={item} form={form} prefix={[field.name, "extData"]}
                                            fullPrefix={[...prefix, field.name, "extData"]} />
                          : <CustomMediaForm index={field.name} prefix={prefix} listName={listName} />}
                        <Form.Item noStyle
                                   dependencies={[[...prefix, listIndex, "sons"], [...prefix, listIndex, "inserted"]]}>
                            {({ getFieldValue }) => {
                                const value = getFieldValue([...prefix, listIndex]);
                                return (value?.sons?.length > 0 || value?.inserted) &&
                                  <MediasFormList prefix={[...prefix, listIndex, "sons"]}
                                                  listName={[field.name, "sons"]}
                                                  label={`“${fieldName || mediaName}”子项`}
                                                  parentName={mediaName} />;
                            }}
                        </Form.Item>
                    </>,
                }]} />
            </div>;
        }} extend={addable && <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
          <Button block icon={<PlusOutlined />} type="primary" ghost
                  onClick={(e: any) => {
                      const filed = form.getFieldValue(prefix);
                      handleMediaInsert(e, { index: filed.length + 1 }, [...prefix, filed.length], () => sortFieldList(prefix));
                  }}>
            添加
          </Button>
        </Form.Item>} />;
    };

    return <MediasFormList prefix={["medias"]} listName={["medias"]} addable />;
}