import { useRequest } from "ahooks";
import { Cascader, Form, Input, Select } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../components/global";

export default function ContentSourceForm({ form, init }: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const [tenants, setTenants] = useState<any>([]);
    const [group, setGroup]: any = useState([]);
    const [cate, setCate]: any = useState([]);
    const [options, setOptions]: any = useState(
        init
            ? [
                {
                    label: `${init.fullName || init.contentName}${init.otherStr ? `-${init.otherStr}` : ""}`,
                    value: init.contentId,
                },
            ]
            : [],
    );
    const typeWatch = useWatch("type", form);

    useEffect(() => {
        form.resetFields();
        getTenants();
        handleSearch("");
    }, []);

    const handleSearch = async (keyword: string) => {
        const { systemCode, type } = form.getFieldsValue();
        if (!systemCode || !type) return;
        const isContent = type === "content";
        return jsonRequest
            .post(`/api/request`, {
                path: isContent ? `/v1/contents/tags` : `/v1/content/cates/group-trees`,
                method: "get",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: { systemCode, type, keyword },
            })
            .then((resp: any) => resp.json())
            .then(({ data }: any) => {
                if (isContent)
                    data &&
                    setOptions(
                        data.map((e: any) => ({
                            label: `${e.fullName || e.contentName}${e.otherStr ? `-${e.otherStr}` : ""}`,
                            value: e.id,
                            ...e,
                        })),
                    );
                else {
                    setGroup(data);
                    if (init?.groupId) {
                        const cate = data.find((e: any) => e.id === init.groupId);
                        setCate(cate?.trees);
                    }
                }
            });
    };

    const onConditionChange = () => {
        const { systemCode, type, content } = form.getFieldsValue();
        if (!systemCode || !type) return;
        handleSearch("");
    };

    const { run } = useRequest(handleSearch, {
        debounceWait: 500,
        manual: true,
    });

    const getTenants = () => {
        jsonRequest
            .post("/api/request", {
                path: "/v1/systems/tenant",
                method: "get",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: {},
            })
            .then((resp: any) => resp.json())
            .then(({ data }) => {
                setTenants(data);
            });
    };

    const onSelect = ({ value }: any) => {
        const option = options.find(
            (e: any) => e.value === value,
        );
        form.setFieldValue("result", option);
    };

    const onGroupSelect = (groupId: any) => {
        const cate = group.find((e: any) => e.id === groupId);
        form.setFieldValue("category", null);
        setCate(cate?.trees);
    };

    const onCateSelect = (value: any) => {
        const id = value[value.length - 1];
        const { sons, ...found }: any = findNodeById(cate, id);
        form.setFieldValue("result", { ...found, groupId: form.getFieldValue("groupId") });
    };

    function findNodeById(tree: any, targetId: any) {
        for (let i = 0; i < tree.length; i++) {
            if (tree[i].id === targetId)
                return tree[i];
            if (tree[i].sons) {
                const found: any = findNodeById(tree[i], targetId);
                if (found) return found;
            }
        }

        // 如果没有找到目标节点，则返回 null
        return null;
    }

    const ContentCateSelector = () => {
        return (
            <>
                <Form.Item
                    label="分组"
                    name="groupId"
                    rules={[{ required: true, message: "不能为空" }]}>
                    <Select
                        defaultActiveFirstOption={false}
                        onSelect={onGroupSelect}
                        options={group.map((e: any) => ({ label: e.groupName, value: e.id }))}
                    />
                </Form.Item>
                <Form.Item
                    label="分类"
                    name="category"
                    initialValue={[init?.id]}
                    rules={[{ required: true, message: "不能为空" }]}>
                    <Cascader
                        onChange={onCateSelect}
                        changeOnSelect
                        showCheckedStrategy={Cascader.SHOW_CHILD}
                        fieldNames={{ label: "cateName", value: "id", children: "sons" }}
                        options={cate}
                    />
                </Form.Item>
            </>
        );
    };

    return (
        <Form
            form={form}
            size="middle"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            initialValues={init}
            className="source-form"
        >
            <Form.Item name="result" hidden> <Input /></Form.Item>
            <Form.Item
                label="知识体系"
                name="systemCode"
                rules={[{ required: true, message: "不能为空" }]}
            >
                <Select
                    onChange={onConditionChange}
                    showSearch
                    optionFilterProp="label"
                    options={tenants.map((e: any) => ({
                        value: e.systemCode,
                        label: e.systemName,
                    }))}
                />
            </Form.Item>
            <Form.Item label="类型" name="type" rules={[{ required: true, message: "不能为空" }]}>
                <Select
                    onChange={onConditionChange}
                    options={[
                        { value: "content", label: "内容" },
                        { value: "content_cate", label: "分类" },
                    ]}
                />
            </Form.Item>
            {typeWatch === "content" ? (
                <Form.Item
                    label="请选择内容"
                    name="content"
                    rules={[{ required: true, message: "不能为空" }]}
                    initialValue={init?.id}
                >
                    <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        labelInValue={true}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={run}
                        onSelect={onSelect}
                        options={options}
                    />
                </Form.Item>
            ) : (
                <ContentCateSelector />
            )}
        </Form>
    );
}
